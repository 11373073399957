<form [formGroup]="_filterForm">
    <div class="flex-container">
        <mat-form-field class="form-field flex-item">
            <mat-select [formControlName]="'orderCorporationID'" placeholder="Corporation">
                <mat-option *ngFor="let option of _corporations" [value]="option.value">
                    {{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-field flex-item">
            <mat-select [formControlName]="'orderTypes'" placeholder="Order Type" multiple="true">
                <mat-option *ngFor="let option of _orderTypeOptions" [value]="option.value">
                    {{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <lib-jurisdiction-selection (formReady)="jurisdictionFormChanges($event)" [defaultCountry]="'CA'"
        [multiSelect]="true" [countryFieldName]="'OrderCountries'"
        [jurisdictionFieldName]="'OrderJurisdictions'"></lib-jurisdiction-selection>

    <div class="flex-container">
        <mat-form-field class="form-field flex-item">
            <mat-label>Audit Date From</mat-label>
            <input matInput [matDatepicker]="auditDateFrom" [formControlName]="'auditDateFrom'"
                placeholder="Audit Date From">
            <mat-datepicker-toggle matSuffix [for]="auditDateFrom"></mat-datepicker-toggle>
            <mat-datepicker #auditDateFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-field flex-item">
            <mat-label>Audit Date To</mat-label>
            <input matInput [matDatepicker]="auditDateTo" [formControlName]="'auditDateTo'" placeholder="Audit Date To">
            <mat-datepicker-toggle matSuffix [for]="auditDateTo"></mat-datepicker-toggle>
            <mat-datepicker #auditDateTo></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="flex-container">
        <mat-form-field class="form-field flex-item">
            <mat-select [formControlName]="'auditStateID'" placeholder="Audit State">
                <mat-option *ngFor="let option of _auditStateOptions" [value]="option.value">
                    {{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="spacer-small"></div>

    <div>
        <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Clear filters button"
            (click)="onClear()" [disabled]="!_filterForm.dirty">
            Clear Filters
        </button>
        <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Filter button" type="submit"
            (click)="onFilter()">
            Filter
        </button>
    </div>
</form>