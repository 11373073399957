import { Component } from '@angular/core';
import { OrderAudit, OrderAuditsRepositoryService } from 'reg-hub-common';
import { ListComponent } from 'reg-hub-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-audit-list',
  templateUrl: './order-audit-list.component.html',
  styleUrls: ['./order-audit-list.component.css']
})
export class OrderAuditListComponent extends ListComponent<OrderAudit> {
  columnsToDisplay: string[] = [
    'corporation', 
    'orderTypeID', 
    'orderCountry', 
    'orderJurisdiction', 
    'auditStateID', 
    'auditedByName',
    'view'];

  constructor(
    repo: OrderAuditsRepositoryService,
    private router: Router
  ) {
    super(repo);
    this.repo = repo;
  }

  protected viewOrder(order: OrderAudit, newTab: boolean) {
      if (newTab) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['orders', order.orderID])
        );
    
        window.open(url, '_blank');
      }
      else {
        this.router.navigate(['orders', order.orderID])
      }
    }
}