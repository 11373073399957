import { Component, OnDestroy } from '@angular/core';
import { catchError, forkJoin, of } from 'rxjs';
import { DatabaseOverview, Order, OrderRepositoryService } from 'reg-hub-common';
import { DatabaseOverviewService } from '../../../services/database-overview/database-overview.service';
import { MatTableDataSource } from '@angular/material/table';
import { QueueMonitorsRepositoryService } from '../../../services/queue-monitors/queue-monitors-repository.service';
import { ScheduledMonitorsRepositoryService } from '../../../services/scheduled-monitors/scheduled-monitors-repository.service';
import { OrdersHubService } from '../../../services/hubs/orders-hub/orders-hub.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnDestroy {

  protected _loading: boolean = false;

  protected _failedOrders: Order[] = [];
  protected _totalFailedOrders: number = 0;

  protected _manualOrders: Order[] = [];
  protected _totalManualOrders: number = 0;

  protected _databaseOverviews!: MatTableDataSource<DatabaseOverview, MatPaginator>;

  constructor(
    private ordersRepo: OrderRepositoryService,
    private databaseOverviewRepo: DatabaseOverviewService,
    private queueMonitorRepo: QueueMonitorsRepositoryService,
    private scheduledMonitorRepo: ScheduledMonitorsRepositoryService,
    private ordersHubService: OrdersHubService) { }

  ngOnInit(): void {
    this._loading = true;
    this.ordersHubService.connect();

    const failedOrdersRequest = this.ordersRepo.getTopTenFailedOrders().pipe(catchError(() => of({ total: 0, resource: [] })));
    const manualOrdersRequest = this.ordersRepo.getTopTenManualOrders().pipe(catchError(() => of({ total: 0, resource: [] })));
    const databaseOverviewRequest = this.databaseOverviewRepo.getDatabaseOverviews().pipe(catchError(() => of([] as DatabaseOverview[])));

    //Take the requests for all 4 panels, combine them into one observable, and only set loading to false when all are complete
    forkJoin({
      failedOrders: failedOrdersRequest,
      manualOrders: manualOrdersRequest,
      databaseOverviews: databaseOverviewRequest,
      queueMonitors: this.queueMonitorRepo.getFirstPageSubscribable(),
      scheduledMonitorRepo: this.scheduledMonitorRepo.getFirstPageSubscribable()
    }).subscribe({ 
      next: responses => {
        this._databaseOverviews = new MatTableDataSource(responses.databaseOverviews);
        this.ordersHubService.setInitialManualOrders(responses.manualOrders.resource);
        this.ordersHubService.setInitialFailedOrders(responses.failedOrders.resource);

        this.subscribeToOrderUpdates();
      },
      complete: () => this._loading = false 
    });

    // register for updates from failed, manual, handled groups
    this.ordersHubService.joinDashboardGroups();
  }

  private subscribeToOrderUpdates() {
    // Subscribe to manual orders updates
    this.ordersHubService.manualOrders$.subscribe((orders) => {
      this._manualOrders = orders;
      this._totalManualOrders = orders.length;
    });

    // Subscribe to failed orders updates
    this.ordersHubService.failedOrders$.subscribe((orders) => {
      this._failedOrders = orders;
      this._totalFailedOrders = orders.length;
    });
  }

  ngOnDestroy() {
    // Leave manual / failed / handled order groups if dashboard is not active
    this.ordersHubService.leaveDashboardGroups();
  }
}
