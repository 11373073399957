import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { eEnvelopeStatusType, Envelope, EnvelopeOrder, EnvelopeRepositoryService } from 'reg-hub-common';
import { BehaviorSubject, concatMap, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-envelope-orders-popup',
  templateUrl: './envelope-orders-popup.component.html',
  styleUrls: ['./envelope-orders-popup.component.css']
})
export class EnvelopeOrdersPopupComponent implements OnInit, OnDestroy {
  displayedColumns = [
    "reference",
    "added",
    "type",
    "order-status",
    "external-status",
    "view",
    "delete"
  ];

  envelope!: Envelope;
  envelopeOrders$ = new BehaviorSubject<EnvelopeOrder[]>(this.envelope?.envelopeOrders ?? [])

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Envelope,
    private envelopeService: EnvelopeRepositoryService,
    private router: Router) {
    this.envelope = data;
  }

  ngOnInit(): void {
    this.envelopeOrders$.next(this.envelope?.envelopeOrders ?? [])
    this.envelopeService.resource$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(envelopes => {
        const envelope = envelopes.find(env => env.id === this.envelope.id);
        this.envelopeOrders$.next(envelope?.envelopeOrders ?? []);
      })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  viewOrder(eo: EnvelopeOrder) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['orders', eo.orderID])
    );

    window.open(url, '_blank');
  }

  onDelete(eo: EnvelopeOrder) {
    this.envelopeService.removeOrderFromEnvelope(eo.envelopeID, eo.orderID)
      .subscribe(() => this.envelopeService.refresh());
  }

  disableDeletion() {
    return !this.envelope.envelopeStatus.allowOrdersRemoved;
  }
}
