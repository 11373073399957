<mat-sidenav-container>
    <mat-sidenav [opened]="isSidenavOpen" mode="side" role="navigation">
    <div class="mat-sidenav-wrapper">
        <div class="mat-nav-wrapper">
        <!-- Main nav items -->
        <mat-nav-list>
            <a
            *ngFor="let item of navItems"
            [routerLink]="item.link"
            class="inactiveRow"
            routerLinkActive="activeRow"
            [attr.data-testid]="'side-menu-option-' + item.label.replaceAll(' ', '-').toLowerCase()"
            >
            <div class="list-item" matRipple>
                <span class="list-item-icon" routerLinkActive="activeIcon">
                <mat-icon>{{ item.icon }}</mat-icon>
                </span>
                <span
                class="list-item-label"
                routerLinkActive="activeText"
                matBadge="{{
                    item.label === 'Audit Queue'
                    ? pendingAuditCount
                    : (item.label === 'Messages' ? unreadMessageCount : '')
                }}"
                matBadgeSize="small"
                matBadgeOverlap="false"
                [matBadgeHidden]="
                    (item.label === 'Audit Queue' && pendingAuditCount < 1) ||
                    (item.label === 'Messages' && unreadMessageCount < 1)
                "
                >
                {{ item.label }}
                </span>
            </div>
            </a>
        </mat-nav-list>
        </div>
    </div>
    </mat-sidenav>

    <mat-sidenav-content>
    <div class="spacer-small"></div>
    <div class="padding-sides-15">
        <lib-search-bar
        [shouldShowExternalOrderNumber]="true"
        (search)="onSearch($event)"
        data-testid="side-menu-search-bar"
        ></lib-search-bar>
    </div>
    <div toastContainer aria-live="polite"></div>
    <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>