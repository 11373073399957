import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { concatMap, forkJoin, interval, Observable, Subject, switchMap, take, takeUntil, takeWhile } from 'rxjs';
import { BatchSummaryRepositoryService, BatchSummary, ListComponent, eBatchState, eOrderStatusType } from 'reg-hub-common';

@Component({
  selector: 'app-batch-summaries',
  templateUrl: './batch-summaries.component.html',
  styleUrls: ['./batch-summaries.component.css']
})
export class BatchSummariesComponent extends ListComponent<BatchSummary> implements OnInit, OnDestroy {
  displayedColumns = [
    "batchType",
    "batchState",
    "draftOrders",
    "pendingOrders",
    "completedOrders",
    "totalOrders"
  ]

  private onDestroy$ = new Subject<void>();
  protected pageSize = 4;
  protected includeCompleted = false;

  constructor(
    protected batchSummaryRepository: BatchSummaryRepositoryService,
    private router: Router) {
    super(batchSummaryRepository);
  }

  ngOnInit(): void {
    let params = new HttpParams()
      .set('pageSize', this.pageSize)
      .set('IsVisible', true);

    this.repo.getFirstPage(this.setParamsWithoutCompleted(params));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setParamsWithoutCompleted(params: HttpParams): HttpParams {
    return params
      .append('BatchStateIDs', eBatchState.preProcessing)
      .append('BatchStateIDs', eBatchState.review)
      .append('BatchStateIDs', eBatchState.processing)
      .append('BatchStateIDs', eBatchState.processingFailed)
      .append('BatchStateIDs', eBatchState.stopped)
      .append('BatchStateIDs', eBatchState.audit);
  }

  setParamsWithCompleted(params: HttpParams): HttpParams {
    return this.setParamsWithoutCompleted(params)
      .append('BatchStateIDs', eBatchState.completed);
  }

  isCompletedFilterChange() {
    if (this.includeCompleted) {
      this.repo.filter(this.setParamsWithCompleted(new HttpParams()));
    } else {
      this.repo.filter(this.setParamsWithoutCompleted(new HttpParams()));
    }
  }

  protected getButtonColor(numOrders: number) {
    let value = 255 - numOrders * 5;

    if (value >= 205 && numOrders !== 0) {
      value = 205;
    } else if (value < 0) {
      value = 0;
    }

    return { 'background-color': `rgb(255, ${value}, ${value})` };
  }

  protected viewBatch(batchSummary: BatchSummary) {
      this.router.navigate(['batches','collection', batchSummary.collectionID, 'batches', batchSummary.id])
  }

  protected onViewDraftOrders(batchSummary: BatchSummary) {
    const params = {
      orderStatusTypeIDs: [ eOrderStatusType.draft, eOrderStatusType.historicalDraft ],
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['batches','collection', batchSummary.collectionID, 'batches', batchSummary.id], 
      { queryParams: params })
  }

  protected onViewPendingOrders(batchSummary: BatchSummary) {
    const params = {
      orderStatusTypeIDs: [eOrderStatusType.pending,
        eOrderStatusType.documentProcessing,
        eOrderStatusType.failedAutomation,
        eOrderStatusType.requestedAtRegistry,
        eOrderStatusType.awaitingHighVolumeResults,
        eOrderStatusType.exception,
        eOrderStatusType.invalid,
        eOrderStatusType.manualFulfillment,
        eOrderStatusType.outOfRegistryHours,
        eOrderStatusType.staged
      ],
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['batches','collection', batchSummary.collectionID, 'batches', batchSummary.id], 
      { queryParams: params })
  }

  protected onViewCompletedOrders(batchSummary: BatchSummary) {
    const params = {
      orderStatusTypeIDs: [ eOrderStatusType.complete, eOrderStatusType.cancelled, eOrderStatusType.historical ],
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['batches','collection', batchSummary.collectionID, 'batches', batchSummary.id], 
      { queryParams: params })
  }
}